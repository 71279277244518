<template>
  <div class="container" id="home">
    <div class="row">
      <maintop1 ref="newmesstop" @click_pro_left="click_pro_home" />
      <div
        :class="
          (content_w == 'content4' || content_w == 'content10') && flag_nav
            ? 'main_fix_offset right_ann'
            : (content_w == 'content4' || content_w == 'content10') && !flag_nav
            ? 'main_fix_width right_ann_close'
            : flag_nav
            ? 'main_fix_offset col-xl-10'
            : 'main_fix_width col-xl-12'
        "
      >
        <div class="row d-flex justify-content-center p-0 m-0">
          <maintop2 ref="getbalance_home" />
          <Footer />
        </div>
        <div class="row mx-auto">
          <banner class="mb-3 home_banner" />
        </div>
        <hr class="m-0" />
        <div>
          <div class="game_list" v-show="content_w != 'share'">
            <div class="list_card tab-title p-2 mx-xl-auto">
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content4'), (type = 'casino')"
                :class="{ list_hover: content_w == 'content4' }"
              >
                <img
                  src="@/assets/img/main/ic2_slot.png"
                  :title="$t('text_igaming').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content16'), (type = 'a1')"
                :class="{ list_hover: content_w == 'content16' }"
              >
                <img
                  src="@/assets/img/main/ic2_slot_gold.png"
                  :title="$t('text_igaming').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content14'), (type = 'GUESS')"
                v-if="code != null"
                :class="{ list_hover: content_w == 'content14' }"
              >
                <img
                  src="@/assets/img/main/favorites.png"
                  :title="$t('text_favorites').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content0'"
                v-if="code == null"
                :class="{ list_hover: content_w == 'content0' }"
              >
                <img
                  src="@/assets/img/main/mini.png"
                  :title="$t('text_free').toUpperCase()"
                />
              </div>

              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content1'), (type = 'T1')"
                :class="{ list_hover: content_w == 'content1' }"
              >
                <img
                  src="@/assets/img/main/ic2_minigame.png"
                  :title="$t('text_mini').toUpperCase()"
                />
              </div> -->

              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content11'), (type = 'JILI')"
                :class="{ list_hover: content_w == 'content11' }"
              >
                <img src="@/assets/img/main/jili.png" title="JILI" />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content12'), (type = 'PG')"
                :class="{ list_hover: content_w == 'content12' }"
              >
                <img src="@/assets/img/main/pg.png" title="PG" />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content13'), (type = 'PP')"
                :class="{ list_hover: content_w == 'content13' }"
              >
                <img src="@/assets/img/main/pp.png" title="PP" />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content2'), (type = 'slot')"
                :class="{ list_hover: content_w == 'content2' }"
              >
                <img
                  src="@/assets/img/main/ic2_hot.png"
                  :title="$t('text_hot').toUpperCase()"
                />
              </div>
              <!-- <div
                class="list_name col-4 main_img"
                @click="content_w = 'content15'"
                :class="{ list_hover: content_w == 'content15' }"
              >
                <img
                  src="@/assets/img/main/main_sport.png"
                  :title="$t('text_sport').toUpperCase()"
                />
              </div> -->

              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content3'"
                :class="{ list_hover: content_w == 'content3' }"
              >
                <img
                  src="@/assets/img/main/ic2_pro.png"
                  :title="$t('faq_5').toUpperCase()"
                />
              </div>

              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content5'"
                :class="{ list_hover: content_w == 'content5' }"
              >
                <img
                  src="@/assets/img/main/1420338.png"
                  :title="$t('text_rank').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content6'), (type = 'shoot')"
                :class="{ list_hover: content_w == 'content6' }"
              >
                <img
                  src="@/assets/img/main/shoot.png"
                  :title="$t('text_shoot').toUpperCase()"
                />
              </div>
              <!-- <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content7'), (type = 'mini')"
                :class="{ list_hover: content_w == 'content7' }"
              >
                <img
                  src="@/assets/img/main/minigame.png"
                  :title="$t('text_mini').toUpperCase()"
                />
              </div> -->
              <div
                class="list_name col-4 main_img"
                @click="(content_w = 'content8'), (type = 'virtual')"
                :class="{ list_hover: content_w == 'content8' }"
              >
                <img
                  src="@/assets/img/main/virtual.png"
                  :title="$t('text_virtual').toUpperCase()"
                />
              </div>
              <div
                class="list_name col-4 main_img"
                @click="content_w = 'content9'"
                :class="{ list_hover: content_w == 'content9' }"
              >
                <img
                  src="@/assets/img/main/ic2_free.png"
                  :title="$t('text_free').toUpperCase()"
                />
              </div>

              <!-- <div class="list_name col-4 main_img" @click="content_w = 'content10'"
                :class="{ list_hover: content_w == 'content10' }">
                <img src="@/assets/img/main/invest.png" :title="$t('text_invest').toUpperCase()" />
              </div> -->
            </div>
          </div>

          <div v-show="content_w == 'content0'" class="mobi_margin">
            <freegame />
          </div>

          <div v-show="content_w == 'content1'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-4"
                v-lazy="'http://gameweb.xkzkji.com/' + l.image"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content2'" class="mobi_margin">
            <div class="live_casino tab-inner">
              <div class="live_casino_card">
                <div class="game_slider_card">
                  <div class="col-12 game_logo_img fw-bolder my-3">
                    {{ $t("home_text5").toUpperCase() }}
                  </div>
                  <div
                    v-for="l in content2_slot"
                    :key="l"
                    class="filter_card"
                    @click="
                      game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                    "
                  >
                    <div
                      class="filter_card1"
                      v-lazy:background-image="
                        'http://gameweb.xkzkji.com/' + l.image
                      "
                    ></div>
                    <div class="mask position-absolute top-0 start-0"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content3'" class="mobi_margin">
            <promotionview :index="getanumber()"></promotionview>
          </div>

          <div v-if="content_w == 'content4'" class="mobi_margin">
            <div class="row mx-auto">
              <!-- <newmain @which_type="whichtype_fun" /> -->
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div
                      :class="whichtype == 'slot' ? 'order-1' : 'order-2'"
                      class="container"
                    >
                      <homeslotgame />
                    </div>
                    <!-- <div
                      :class="whichtype == 'lotto' ? 'order-1' : 'order-2'"
                      class="container"
                    >
                      <homecasinogame
                        @watch_newWindowurl="home_newWindowurl"
                        :show_id="'lotto'"
                      />
                    </div> -->

                    <div
                      :class="whichtype == 'fish' ? 'order-1' : 'order-2'"
                      class="container"
                    >
                      <div class="row home_game_arrow home_game_fish">
                        <div class="d-flex justify-content-between">
                          <div class="game_logo_img fw-bolder my-3">
                            {{ $t("text_fish").toUpperCase() }}
                          </div>
                          <div class="top_arrow">
                            <div class="left_right">
                              <button
                                class="btn_arrow"
                                @click="turn('fish', 'left')"
                              >
                                <span role="img" aria-label="left"
                                  ><svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="left"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                                    ></path>
                                  </svg>
                                </span></button
                              ><button
                                class="btn_arrow"
                                @click="turn('fish', 'right')"
                              >
                                <span role="img" aria-label="right"
                                  ><svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="right"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                                    ></path>
                                  </svg>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <swiper
                          :loop="swiper_casino_options.loop"
                          :speed="swiper_casino_options.speed"
                          :slidesPerView="swiper_casino_options.slidesPerView"
                          :spaceBetween="swiper_casino_options.spaceBetween"
                          :breakpoints="swiper_casino_options.breakpoints"
                          :navigation="swiper_casino_options.navigation"
                        >
                          <swiper-slide class="home_game_img">
                            <div class="home_game_img_div">
                              <div
                                :class="css_list"
                                @click="game('', 'slot', '42', 'JILI', 'slot')"
                                class="game_item"
                              >
                                <img
                                  src="http://gameweb.xkzkji.com/images/jili/GameID_42_EN.png"
                                  class="w-100"
                                />
                                <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >DINOSAUR TYCOON</span
                                > -->
                              </div>
                            </div>
                          </swiper-slide>
                          <swiper-slide class="home_game_img">
                            <div class="home_game_img_div">
                              <div
                                :class="css_list"
                                @click="game('', 'slot', '20', 'JILI', 'slot')"
                                class="game_item"
                              >
                                <img
                                  src="http://gameweb.xkzkji.com/images/jili/GameID_20_EN.png"
                                  class="w-100"
                                />
                                <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >YB</span
                                > -->
                              </div>
                            </div>
                          </swiper-slide>
                          <swiper-slide class="home_game_img">
                            <div class="home_game_img_div">
                              <div
                                :class="css_list"
                                @click="game('', 'slot', '30', 'JILI', 'slot')"
                                class="game_item"
                              >
                                <img
                                  src="http://gameweb.xkzkji.com/images/jili/GameID_30_EN.png"
                                  class="w-100"
                                />
                                <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >WM</span
                                > -->
                              </div>
                            </div>
                          </swiper-slide>
                          <swiper-slide class="home_game_img">
                            <div class="home_game_img_div">
                              <div
                                :class="css_list"
                                @click="game('', 'slot', '1', 'JILI', 'slot')"
                                class="game_item"
                              >
                                <img
                                  src="http://gameweb.xkzkji.com/images/jili/GameID_1_EN.png"
                                  class="w-100"
                                />
                                <!-- <span
                                  class="position-absolute start-0 bottom-0 text-center w-100"
                                  >EVO</span
                                > -->
                              </div>
                            </div>
                          </swiper-slide>
                          <swiper-slide class="home_game_img">
                            <div class="home_game_img_div">
                              <div
                                :class="css_list"
                                @click="game('', 'slot', '32', 'JILI', 'slot')"
                                class="game_item"
                              >
                                <img
                                  src="http://gameweb.xkzkji.com/images/jili/GameID_32_EN.png"
                                  class="w-100"
                                />
                              </div>
                            </div>
                          </swiper-slide>
                        </swiper>
                      </div>
                    </div>
                    <div
                      :class="whichtype == 'casino' ? 'order-1' : 'order-2'"
                      class="container"
                    >
                      <homecasinogame
                        @watch_newWindowurl="home_newWindowurl"
                        :show_id="'casino'"
                      />
                    </div>
                    <div
                      :class="whichtype == 'slot_pp' ? 'order-1' : 'order-2'"
                      class="container"
                    >
                      <homecasinogame
                        @watch_newWindowurl="home_newWindowurl"
                        :show_id="'slot_pp'"
                      />
                    </div>
                    <div
                      :class="whichtype == 'casino_evo' ? 'order-1' : 'order-2'"
                      class="container"
                    >
                      <homecasinogame
                        @watch_newWindowurl="home_newWindowurl"
                        :show_id="'casino_evo'"
                      />
                    </div>
                    <!-- <div
                      :class="whichtype == 'sport' ? 'order-1' : 'order-2'"
                      class="container"
                    >
                      <homecasinogame
                        @watch_newWindowurl="home_newWindowurl"
                        :show_id="'sport'"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
              <img
                src="@/assets/img/main/sponser_cbf.jpg"
                class="right_img_ann offset-2 d-none col-2"
              />
            </div>
          </div>

          <div v-if="content_w == 'content5'" class="mobi_margin">
            <rankview></rankview>
          </div>

          <div v-show="content_w == 'content6'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-3 col-4"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content7'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-3 col-4"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content8'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-xl-4 col-6"
                v-lazy="l.bannerName"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content9'" class="mobi_margin">
            <freegame9 />
          </div>

          <div v-if="content_w == 'content10'" class="mobi_margin">
            <investview />
            <!-- <financeview /> -->
            <giftview />
            <img
              src="@/assets/img/main/sponser_cbf.jpg"
              class="right_img_ann offset-2 d-none col-2"
            />
          </div>

          <div v-show="content_w == 'share'" class="mobi_margin">
            <share
              :shareurl="shareurl"
              :share_more_content="share_more_content"
            />
          </div>

          <div v-show="content_w == 'content11'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card c-pointer"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="
                          'http://gameweb.xkzkji.com/' + l.image
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content12'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-4 col-xl-2"
                v-lazy="'http://gameweb.xkzkji.com/' + l.image"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content13'" class="mobi_margin">
            <div class="content_img">
              <img
                v-for="l in list"
                :key="l"
                class="col-4 col-xl-2"
                v-lazy="'http://gameweb.xkzkji.com/' + l.image"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              />
            </div>
          </div>

          <div v-show="content_w == 'content14'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div
                    class="game_slider_card"
                    v-if="list != null && list != ''"
                  >
                    <div
                      v-for="l in list"
                      :key="l"
                      class="filter_card"
                      @click="
                        game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                      "
                    >
                      <div
                        class="filter_card1"
                        v-lazy:background-image="l.iconName"
                      ></div>
                      <div class="mask position-absolute top-0 start-0"></div>
                    </div>
                  </div>

                  <div v-else class="text-center mt-3">
                    <p>{{ $t("nodata") }}</p>
                    <p>{{ $t("text_favorites2") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="content_w == 'content15'" class="mobi_margin">
            <mainsport @watch_newWindowurl="home_newWindowurl" />
          </div>

          <div v-if="content_w == 'content16'" class="mobi_margin">
            <div class="row mx-auto">
              <div class="live_casino tab-inner">
                <div class="live_casino_card">
                  <div class="game_slider_card">
                    <div class="col-12 game_logo_img fw-bolder my-3">
                      {{ $t("home_text7") }}
                    </div>
                    <div class="col-12">
                      <img
                        v-for="l in list"
                        :key="l"
                        v-lazy="l.bannerName"
                        class="col-lg-4 col-6 mb-2 px-2 c-pointer"
                        @click="
                          set_gametype(
                            l.gameType.toUpperCase() +
                              ',' +
                              l.gameName.toUpperCase()
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footertwo />
      </div>
    </div>
    <prewindow></prewindow>
    <bighead
      @flag_val="flag_big_fun"
      v-if="flag_head && code != null"
    ></bighead>
    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>

<script>
import { reactive } from "vue";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
import Cookies from "js-cookie";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import banner from "./Home/Banner.vue";
import promotionview from "./Promotion.vue";
import rankview from "./rank.vue";
import footertwo from "./Home/footer_two.vue";
import Prewindow from "./Home/prewindow.vue";
import bighead from "./Home/big_head_img.vue";
import share from "./share.vue";
import investview from "./invest.vue";
// import financeview from "./finance.vue";
import newWindow from "./iframe.vue";
import giftview from "./gift.vue";
// import newmain from "./new_main.vue";
import homeslotgame from "./home_slot_game.vue";
import homecasinogame from "./home_casino_game.vue";
import freegame from "./free_game.vue";
import freegame9 from "./free_game_9.vue";
import mainsport from "./main_sport.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  data() {
    return {
      content_w: "content0",
      code: Cookies.get("code"),
      userid: "richkingbr",
      type: "T1",
      css_list: "position-relative",
      utype: localStorage.getItem("utype"),
      flag_head: false,
      vip: 0,
      follow: 0,
      shareurl: "",
      big_head_img_set: localStorage.getItem("big_logo"),
      newWindowurl: "",
      whichtype: "slot",
      share_more_content: [],
    };
  },
  components: {
    Footer,
    maintop1,
    maintop2,
    banner,
    promotionview,
    rankview,
    footertwo,
    Prewindow,
    bighead,
    share,
    investview,
    // financeview,
    newWindow,
    giftview,
    // newmain,
    homeslotgame,
    homecasinogame,
    Swiper,
    SwiperSlide,
    freegame,
    freegame9,
    mainsport,
  },
  computed: {
    ...mapGetters(["list", "flag_nav"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    content2_slot: function () {
      if (this.content_w == "content2" && this.list)
        return this.list.slice(0, 40);
      else return [];
    },
  },
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      var action_val =
        t != "shoot" && t != "mini" && t != "virtual" && t != "a1"
          ? "GameLobby"
          : "";
      let param = {
        action: action_val,
        body: {
          gametype: t,
          type: "all",
          uid: localStorage.getItem("uid"),
        },
      };
      this.getGameList(param);
    },
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (type.toUpperCase() == "TCG" || type.toUpperCase() == "SB") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    getanumber: function () {
      return Math.random();
    },
    whichtype_fun: function (e) {
      this.whichtype = e;
    },
    flag_big_fun: function (e, imgval) {
      this.flag_head = e;
      localStorage.setItem("big_logo", imgval);
      this.big_head_img_set = imgval;
    },
    change_con_ig: function () {
      this.content_w = "content4";
      this.type = "casino";
    },
    click_pro_home: function () {
      this.content_w = "content3";
    },
    vip_follow: function (txt) {
      let param = {
        action: "vip_follows",
        body: {
          uid: txt,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (parseFloat(res["vip"]) / 1000000000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000000000 + "B";
        else if (parseFloat(res["vip"]) / 1000000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000000 + "M";
        else if (parseFloat(res["vip"]) / 1000 > 1)
          this.vip = parseFloat(res["vip"]) / 1000 + "K";
        else this.vip = res["vip"];

        if (parseFloat(res["follow"]) / 1000000000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000000000 + "B";
        else if (parseFloat(res["follow"]) / 1000000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000000 + "M";
        else if (parseFloat(res["follow"]) / 1000 > 1)
          this.follow = parseFloat(res["follow"]) / 1000 + "K";
        else this.follow = res["follow"];
      });
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.shareurl = res.url;
          this.share_more_content = res.share_more_content;
        }
      });
    },
    nologin_bigimg: function () {
      let param = {
        action: "Member_unlogin",
        body: {
          uid: this.userid,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.big_head_img_set = res.logo;
        }
      });
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    home_newWindowurl(e) {
      if (e == "close") {
        this.$refs.getbalance_home.getBalance();
        this.$refs.newmesstop.getnewmess();
      }
    },
    set_gametype: function (e) {
      localStorage.setItem("g", e);
      this.$router.push("/slot");
    },
  },
  created() {
    if (this.code != null) {
      this.userid = localStorage.getItem("uid");
      this.share();
    } else {
      this.userid =
        localStorage.getItem("introducer") == null ||
        localStorage.getItem("introducer") == ""
          ? "richkingbr"
          : localStorage.getItem("introducer");

      if (this.userid != "richkingbr") this.nologin_bigimg();
      else this.big_head_img_set = "logo_big";
    }
    this.vip_follow(this.userid);

    if (this.Id == "pro") this.content_w = "content3";
    else this.change_con_ig();
  },
  watch: {
    type: function (e) {
      this.gamelist(e);
    },
    newWindowurl: function () {
      if (this.newWindowurl == null || this.newWindowurl == "") {
        this.home_newWindowurl("close");
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
#login #home .offset-xl-2 {
  margin-left: 0;
}

#login #home .navbar,
#login #home .collaspe {
  display: none;
}
</style>
