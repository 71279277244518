/**
 * Game
 * richkingbr
 *
 */
export const dist2 = [
  //MARK: - Slot

  // {
  //   iconName: require("@/assets/img/game/t1.png"),
  //   gameName: "T1",
  //   gameType: "T1",
  //   gameId: "",
  //   mode: "slot",
  //   device: "mobile",
  //   gameKind: "slot",
  //   Gamestype: "minigame",
  //   bannerName: require("@/assets/img/game/egame_t1.png"),
  // },
  {
    iconName: require("@/assets/img/game/funta.png"),
    gameName: "FUNTA",
    gameType: "FUNTA",
    gameId: "Coming Soon",
    mode: "slot",
    device: "mobile",
    gameKind: "slot",
    Gamestype: "slot",
    bannerName: require("@/assets/img/game/egame_funta.png"),
  },
  {
    iconName: require("@/assets/img/game/pg.png"),
    gameName: "PG",
    gameType: "PG",
    gameId: "GameLobby",
    mode: "slot",
    device: "mobile",
    gameKind: "slot",
    Gamestype: "slot",
    bannerName: require("@/assets/img/game/egame_pg.png"),
  },
  // {
  //   iconName: require("@/assets/img/game/all/live_bg.png"),
  //   gameName: "BG",
  //   gameType: "BG",
  //   gameId: "",
  //   mode: "casino",
  //   device: "mobile",
  //   gameKind: "casino",
  //   Gamestype: "casino",
  //   bannerName:  require("@/assets/img/game/all/live_bg.png"),
  // },
  {
    iconName: require("@/assets/img/game/all/live_yeebet.png"),
    gameName: "YB",
    gameType: "YB",
    gameId: "",
    mode: "casino",
    device: "mobile",
    gameKind: "casino",
    Gamestype: "casino",
    bannerName: require("@/assets/img/game/all/live_yeebet.png"),
  },
  // {
  //   iconName: require("@/assets/img/game/all/live_wm.png"),
  //   gameName: "WM",
  //   gameType: "WM",
  //   gameId: "0",
  //   mode: "casino",
  //   device: "mobile",
  //   gameKind: "casino",
  //   Gamestype: "casino",
  //   bannerName: require("@/assets/img/game/all/live_wm.png"),
  // },
  {
    iconName: require("@/assets/img/game/all/live_evo.png"),
    gameName: "EVO",
    gameType: "EVO",
    gameId: "",
    mode: "casino",
    device: "mobile",
    gameKind: "casino",
    Gamestype: "casino",
    bannerName: require("@/assets/img/game/all/live_evo.png"),
  },
  // {
  //   iconName: require("@/assets/img/game/all/live_xg.png"),
  //   gameName: "XG",
  //   gameType: "XG",
  //   gameId: "xg006",
  //   mode: "casino",
  //   device: "mobile",
  //   gameKind: "casino",
  //   Gamestype: "casino",
  //   bannerName: require("@/assets/img/game/all/live_xg.png"),
  // },
  // {
  //   iconName: require("@/assets/img/game/all/live_ag.png"),
  //   gameName: "AG",
  //   gameType: "AG",
  //   gameId: "0",
  //   mode: "1",
  //   device: "",
  //   gameKind: "casino",
  //   Gamestype: "casino",
  //   bannerName: require("@/assets/img/game/all/live_ag.png"),
  // },
  {
    iconName: require("@/assets/img/game/all/live_pp.png"),
    gameName: "PP",
    gameType: "PP",
    gameId: "101",
    mode: "casino",
    device: "mobile",
    gameKind: "casino",
    Gamestype: "casino",
    bannerName: require("@/assets/img/game/all/live_pp.png"),
  },

  //shoot game
  {
    iconName: require("@/assets/img/shoot/7.png"),
    gameName: "SPACE HUNTER",
    gameType: "FACHAI",
    gameId: "21008",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/7.png"),
  },
  {
    iconName: require("@/assets/img/shoot/14.png"),
    gameName: "BOOM LEGEND",
    gameType: "JILI",
    gameId: "71",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/14.png"),
  },
  {
    iconName: require("@/assets/img/shoot/8.png"),
    gameName: "BAO CHUAN FISHING",
    gameType: "FACHAI",
    gameId: "21004",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/8.png"),
  },
  {
    iconName: require("@/assets/img/shoot/10.png"),
    gameName: "ZOMBIE BONUS",
    gameType: "PS",
    gameId: "PSF-ON-00003",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/10.png"),
  },
  {
    iconName: require("@/assets/img/shoot/11.png"),
    gameName: "POSIDON'S SECRET",
    gameType: "KA",
    gameId: "PoseidonSecret",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/11.png"),
  },
  {
    iconName: require("@/assets/img/shoot/13.png"),
    gameName: "DINOSAUR TYCOON",
    gameType: "JILI",
    gameId: "42",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/13.png"),
  },
  {
    iconName: require("@/assets/img/shoot/9.png"),
    gameName: "DRAGON FORTUNE",
    gameType: "JILI",
    gameId: "60",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/9.png"),
  },
  // {
  //   iconName: require("@/assets/img/shoot/12.png"),
  //   gameName: "THUNDER FIGHTER",
  //   gameType: "CQ9",
  //   gameId: "AT04",
  //   mode: "slot",
  //   device: "",
  //   gameKind: "slot",
  //   Gamestype: "shoot",
  //   bannerName: require("@/assets/img/shoot/12.png"),
  // },
  {
    iconName: require("@/assets/img/shoot/15.png"),
    gameName: "KA FISH HUNTER",
    gameType: "KA",
    gameId: "KAFishHunter",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "shoot",
    bannerName: require("@/assets/img/shoot/15.png"),
  },
  //mini game
  // {
  //   iconName: require("@/assets/img/mini/16.png"),
  //   gameName: "out of bang",
  //   gameType: "RICH",
  //   gameId: "DeepSeaAdventure",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/16.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/17.png"),
  //   gameName: "color game",
  //   gameType: "RICH",
  //   gameId: "ColorGame",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/17.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/18.png"),
  //   gameName: "dragon gate poker",
  //   gameType: "RICH",
  //   gameId: "DragonGatePoker",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/18.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/19.png"),
  //   gameName: "crushing pot",
  //   gameType: "RICH",
  //   gameId: "CrushingPots",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/19.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/20.png"),
  //   gameName: "gem planet",
  //   gameType: "RICH",
  //   gameId: "SlotGem",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/20.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/21.png"),
  //   gameName: "lottery ticket",
  //   gameType: "RICH",
  //   gameId: "InstantLottery",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/21.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/22.png"),
  //   gameName: "ladder climbing",
  //   gameType: "RICH",
  //   gameId: "Ladder",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/22.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/23.png"),
  //   gameName: "rock paper scissors",
  //   gameType: "RICH",
  //   gameId: "RockPaperScissor",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/23.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/24.png"),
  //   gameName: "defuse the bomb",
  //   gameType: "RICH",
  //   gameId: "Bombing",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/24.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/25.png"),
  //   gameName: "football battle",
  //   gameType: "RICH",
  //   gameId: "DuelSoccer",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/25.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/26.png"),
  //   gameName: "snail racing",
  //   gameType: "RICH",
  //   gameId: "SnailRacingSlow",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/26.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/27.png"),
  //   gameName: "red and black",
  //   gameType: "RICH",
  //   gameId: "RedBlackLZ",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/27.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/28.png"),
  //   gameName: "dragon tiger",
  //   gameType: "RICH",
  //   gameId: "DragonTiger",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/28.png"),
  // },
  // {
  //   iconName: require("@/assets/img/mini/31.png"),
  //   gameName: "monkey climb tree",
  //   gameType: "RICH",
  //   gameId: "MonkeyGoGo",
  //   mode: "slot",
  //   device: "web",
  //   gameKind: "slot",
  //   Gamestype: "mini",
  //   bannerName: require("@/assets/img/mini/31.png"),
  // },
  //virtual
  {
    iconName: require("@/assets/img/virtual/vplfl6_fantastic_league_football.png"),
    gameName: "Fantastic League Football",
    gameType: "PP",
    gameId: "vplfl6",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vplfl6_fantastic_league_football.png"),
  },
  {
    iconName: require("@/assets/img/virtual/vpfh3_flat_horse_racing.png"),
    gameName: "Flat Horse Racing",
    gameType: "PP",
    gameId: "vpfh3",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vpfh3_flat_horse_racing.png"),
  },
  {
    iconName: require("@/assets/img/virtual/vppso4_penalty_shootout.png"),
    gameName: "Penalty Shootout",
    gameType: "PP",
    gameId: "vppso4",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vppso4_penalty_shootout.png"),
  },
  {
    iconName: require("@/assets/img/virtual/vpmr9_force_1_racing.png"),
    gameName: "Force 1 Racing",
    gameType: "PP",
    gameId: "vpmr9",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vpmr9_force_1_racing.png"),
  },
  {
    iconName: require("@/assets/img/virtual/vpdr7_greyhound_racing.png"),
    gameName: "Greyhound Racing",
    gameType: "PP",
    gameId: "vpdr7",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vpdr7_greyhound_racing.png"),
  },
  {
    iconName: require("@/assets/img/virtual/vpsc10_steeple_chase.png"),
    gameName: "Steeplechase",
    gameType: "PP",
    gameId: "vpsc10",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vpsc10_steeple_chase.png"),
  },
  {
    iconName: require("@/assets/img/virtual/vpdt11_darts.png"),
    gameName: "Darts",
    gameType: "PP",
    gameId: "vpdt11",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vpdt11_darts.png"),
  },
  {
    iconName: require("@/assets/img/virtual/vplobby_virtual_sports_lobby.png"),
    gameName: "Virtual Sports Lobby",
    gameType: "PP",
    gameId: "vplobby",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "virtual",
    bannerName: require("@/assets/img/virtual/vplobby_virtual_sports_lobby.png"),
  },
  //sport
  // {
  //   iconName: require("@/assets/img/sport/sport_PREMIER.png"),
  //   gameName: "PREMIER",
  //   gameType: "SB",
  //   gameId: "WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=3",
  //   mode: "sport",
  //   device: "web",
  //   gameKind: "sport",
  //   Gamestype: "sport",
  //   bannerName: require("@/assets/img/sport/sport_PREMIER.png"),
  // },
  // {
  //   iconName: require("@/assets/img/sport/sport_NBA.png"),
  //   gameName: "NBA",
  //   gameType: "SB",
  //   gameId: "WebSkinType=3;menutype=0;act=2;sportid=2;leaguekey=56038",
  //   mode: "sport",
  //   device: "web",
  //   gameKind: "sport",
  //   Gamestype: "sport",
  //   bannerName: require("@/assets/img/sport/sport_NBA.png"),
  // },
  //all in slot
  {
    iconName: require("@/assets/img/allinslot/AINSWORTH.png"),
    gameName: "AINSWORTH",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/AINSWORTH.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/AMATIC.png"),
    gameName: "AMATIC",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/AMATIC.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/APEX.png"),
    gameName: "APEX",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/APEX.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/APOLLO.png"),
    gameName: "APOLLO",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/APOLLO.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/ARISTOCRAT.png"),
    gameName: "ARISTOCRAT",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/ARISTOCRAT.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/EGT.png"),
    gameName: "EGT",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/EGT.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/gclub.png"),
    gameName: "gclub",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/gclub.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/igrosoft.png"),
    gameName: "igrosoft",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/igrosoft.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/igt.png"),
    gameName: "igt",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/igt.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/microgaming.png"),
    gameName: "microgaming",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/microgaming.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/netent.png"),
    gameName: "netent",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/netent.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/novomatic.png"),
    gameName: "novomatic",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/novomatic.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/pragmatic.png"),
    gameName: "pragmatic",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/pragmatic.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/quickspin.png"),
    gameName: "quickspin",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/quickspin.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/scientific_games.png"),
    gameName: "scientific_games",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/scientific_games.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/vegas.png"),
    gameName: "vegas",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/vegas.png"),
  },
  {
    iconName: require("@/assets/img/allinslot/wazdan.png"),
    gameName: "wazdan",
    gameType: "A1",
    gameId: "Coming Soon",
    mode: "slot",
    device: "",
    gameKind: "slot",
    Gamestype: "a1",
    bannerName: require("@/assets/img/allinslot/wazdan.png"),
  },
];
